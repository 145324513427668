const activeNav = document.querySelectorAll('.navigation__active');
const navigation = document.querySelector('.navigation__nav');
const checkBox = document.querySelector('.navigation__checkbox');
const navBtn = document.querySelector('.navigation__button');
const hoverOrClick = document.querySelectorAll('.hover__or__click');

activeNav.forEach(item => {
  item.addEventListener('click', e => {
    e.preventDefault();
  });
});
//  hiding part of nav or showing it. Otherwise see the buttons on the hamburger nav transition visibly on screen.
const setNavStyles = () => {
  const navigation = document.querySelector('.navigation__nav');
  const checkBox = document.querySelector('.navigation__checkbox');
  if (checkBox.checked) {
    checkBox.checked = false;
  }
  if (window.innerWidth >= 900) {
    navigation.classList.add('navigation__nav--open');
  } else {
    navigation.classList.remove('navigation__nav--open');
  }
};
window.addEventListener(
  'resize',
  () => {
    setNavStyles();
  },
  true,
);
window.addEventListener('load', () => {
  setNavStyles(); // Ensure styles are set correctly on page load
});

// handles button for nav on small screen.
navBtn.addEventListener('click', () => {
  if (checkBox.checked) {
    navigation.classList.remove('navigation__nav--open');
  } else {
    navigation.classList.add('navigation__nav--open');
  }
});

// handles closing drop down menus and rotating of chevron, includes dropping focus in case someone is tabbing through
const removeExpanded = (excludeBtn = null) => {
  hoverOrClick.forEach(item => {
    if (item !== excludeBtn) {
      let dropdown = item.querySelector('.navigation__dropdown');
      if (dropdown) {
        dropdown.classList.remove('navigation__dropdown--active');
      }
      item.setAttribute('aria-expanded', 'false');

      // Remove rotation from chevron
      let chevron = item.querySelector('.navigation__chevron');
      if (chevron) {
        chevron.classList.remove('navigation__chevron--rotated');
      }

      // Remove focus from item and its dropdown links
      if (dropdown) {
        dropdown.querySelectorAll('a, button').forEach(element => {
          element.blur();
        });
      }
    }
  });
};

// sets up how the navigation behaves so dropdowns appear as either hover effects or click effects.
const changeHoverClick = () => {
  hoverOrClick.forEach(item => {
    removeExpanded();
    if (window.innerWidth < 900) {
      item.classList.remove('navigation__item--dropdown');
    } else {
      item.classList.add('navigation__item--dropdown');
    }
  });
};

// Initial check
changeHoverClick();

// Attach the resize event listener to change how nav behaves
window.addEventListener('resize', changeHoverClick);

// handles all event listeners for the dropdown menus.
hoverOrClick.forEach(btn => {
  // handles open closing dropdowns on clicks, tabs
  const toggleDropdown = (evt, fromFocus = false) => {
    const expanded = evt.currentTarget.getAttribute('aria-expanded') === 'true';

    // Close all other dropdowns first, except the current one
    removeExpanded(evt.currentTarget);

    // If not expanded, or if triggered by focus, handles opening them properly and rotating chevron
    if (!expanded || fromFocus) {
      let dropdown = evt.currentTarget.querySelector('.navigation__dropdown');
      if (dropdown) {
        dropdown.classList.add('navigation__dropdown--active');
      }
      evt.currentTarget.setAttribute('aria-expanded', 'true');
      evt.currentTarget
        .querySelector('.navigation__chevron')
        .classList.add('navigation__chevron--rotated');
    } else {
      let dropdown = evt.currentTarget.querySelector('.navigation__dropdown');
      if (dropdown) {
        dropdown.classList.remove('navigation__dropdown--active');
      }
      evt.currentTarget.setAttribute('aria-expanded', 'false');
      evt.currentTarget
        .querySelector('.navigation__chevron')
        .classList.remove('navigation__chevron--rotated');
    }
  };

  //used to close dropdown when mouse leaves currently, ie for hover
  const closeDropdown = evt => {
    let dropdown = evt.target.querySelector('.navigation__dropdown');
    if (dropdown) {
      dropdown.classList.remove('navigation__dropdown--active');
    }
    evt.target.setAttribute('aria-expanded', 'false');
    evt.target
      .querySelector('.navigation__chevron')
      .classList.remove('navigation__chevron--rotated');
  };

  // Handle click events for small screens
  btn.addEventListener('click', evt => {
    if (window.innerWidth < 900) {
      evt.preventDefault();
      console.log('clicked');
      const isActive =
        evt.target.parentNode.classList.contains('navigation__active');
      if (evt.target.parentNode.dataset.nav === 'home' && !isActive) {
        window.location.href = 'index.html';
      } else {
        toggleDropdown(evt);
      }
    }
  });

  // Handle keydown events (both Enter and Space) for all screen sizes
  btn.addEventListener('keydown', e => {
    const currentTargetNav = e.target
      .querySelector('.navigation__text')
      .textContent.trim()
      .toLowerCase();
    const mainNav = ['home', 'capabilities', 'media center', 'about us'];
    const navObj = {
      home: 'index.html',
      capabilities: 'capabilities.html',
      'media center': 'media.html',
      'about us': 'about.html',
    };
    const windowWidth = window.innerWidth;
    const isActive = e.target.classList.contains('navigation__active');
    if (e.key === 'Enter' || e.key === ' ') {
      // Check if the clicked item is a main navigation item
      if (windowWidth < 900 && currentTargetNav === 'home' && !isActive) {
        window.location.href = navObj[currentTargetNav];
      } else if (
        windowWidth > 900 &&
        mainNav.includes(currentTargetNav) &&
        !isActive
      ) {
        window.location.href = navObj[currentTargetNav];
      } else {
        e.preventDefault();
        toggleDropdown(e);
      }
    }
  });

  //NOTE: this is being used so that the focusin will work on mobile if a keyboard is being used. This allows the dropdown to auto drop.
  let usingKeyboard = false;

  // Set the flag when a keyboard event occurs
  document.addEventListener('keydown', () => {
    usingKeyboard = true;
  });

  // Reset the flag after a short delay to ensure it doesn't affect subsequent mouse events
  document.addEventListener('mousedown', () => {
    usingKeyboard = false;
  });

  // Handle focus within events
  btn.addEventListener('focusin', evt => {
    if (usingKeyboard && evt.target.classList.contains('navigation__link')) {
      toggleDropdown(evt, true);
    }
  });

  // Handle hover events for larger screens
  btn.addEventListener('mouseenter', evt => {
    if (window.innerWidth >= 900) {
      toggleDropdown(evt);
    }
  });

  btn.addEventListener('mouseleave', evt => {
    if (window.innerWidth >= 900) {
      closeDropdown(evt);
    }
  });
});

// Ensure that links within the dropdown still work
document.querySelectorAll('.navigation__dropdown--link').forEach(link => {
  link.addEventListener('click', evt => {
    evt.stopPropagation();
  });
  link.addEventListener('keydown', function (e) {
    if (e.key === 'Enter' || e.key === ' ') {
      e.preventDefault();
      this.click();
    }
  });
});

// Function to handle closing of dropdowns
const closeAllDropdowns = () => {
  hoverOrClick.forEach(item => {
    let dropdown = item.querySelector('.navigation__dropdown');
    if (dropdown) {
      dropdown.classList.remove('navigation__dropdown--active');
    }
    item.setAttribute('aria-expanded', 'false');
    item
      .querySelector('.navigation__chevron')
      .classList.remove('navigation__chevron--rotated');
  });
};

// Function to handle clicks outside dropdowns
const handleClickOutside = event => {
  let isClickInside = false;

  hoverOrClick.forEach(btn => {
    if (btn.contains(event.target)) {
      isClickInside = true;
    }
  });

  if (!isClickInside) {
    closeAllDropdowns();
  }
};

// Attach the global click event listener
document.addEventListener('click', handleClickOutside);

// Cleanup the event listener when not needed (optional, if you're dynamically adding/removing elements)
window.addEventListener('beforeunload', () => {
  document.removeEventListener('click', handleClickOutside);
});
